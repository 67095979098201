<template>

  <div class="service_form_details">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="6"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              {{ itemsMaster.itemNo }}
            </h1>
            <p class="log_info">
              Created by {{ itemsMaster.createdBy ? itemsMaster.createdBy.name : '' }} on {{ dateFormatWithTime(itemsMaster.createdAt) }}<br>Last updated on {{ dateFormatWithTime(itemsMaster.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="6"
        cols="12"
      >
        <div class="user_block ac_type">
          <div class="user_avatar" />
          <div class="user_info">
            <h6>Status</h6>
            <h5 class="text-capitalize">
              {{ itemsMaster.status ? itemsMaster.status.replace("-", " ") : '' }}
            </h5>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      class="mb-3 p-2 calllog-details serviceForm_details"
    >
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Items Master Information</h3>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Item Name
            </b-th>
            <b-td>{{ itemsMaster.description }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Unit
            </b-th>
            <b-td>{{ itemsMaster.unit }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Supplier/Vendor
            </b-th>
            <b-td>{{ itemsMaster.supplier ? itemsMaster.supplier.companyName : '' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTableSimple, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  data() {
    return {
      itemsMaster: {},
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
    }
  },
  mounted() {
    this.$http.get(`purchase/items-master/${this.$route.params.id}/show`)
      .then(response => {
        this.itemsMaster = response.data.data || {}
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
}
</script>

<style scoped>
  .timeline-variant-info .timeline-item-point {
    background-color: #00cfe8 !important;
  }
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
